.general-info {
  width: 100vw;
  max-width: 1440px;
  box-sizing: border-box;
  padding-right: 40px;
}

.general-info__container {
  width: 100%;
  max-width: 1320px;
  display: flex;
  flex-direction: column;
}

.general-info__content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 60px;
}

.general-info__img-box {
  width: 100%;
  max-width: 480px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border-radius: 600px;
  background: var(--general-info-neutral-primary);
  overflow: hidden;
  will-change: transform;
}

.general-info__title {
  color: var(--neutral-secondary);
  font-size: 60px;
  line-height: 1.1;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.general-info__title_size_big {
  font-size: 90px;
  line-height: 1.07;
  letter-spacing: -1.8px;
}

.general-info__title_type_mobile {
  display: none;
}

.ai__span {
  font-weight: 500;
  color: var(--main-primary-active);
  white-space: nowrap;
}

.general-info__text {
  width: 34.9%;
  color: var(--neutral-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.87;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .general-info__img-box {
    border-radius: 0px 600px 600px 0px;
  }
}

@media (max-width: 1260px) {
  .general-info__container {
    gap: 40px;
  }

  .general-info__content {
    gap: 30px;
  }

  .general-info__img-box {
    max-width: 100%;
  }

  .general-info__title {
    display: none;
    padding-left: 40px;
  }

  .general-info__title_size_big {
    font-size: 60px;
    line-height: 1.1;
    letter-spacing: -1.2px;
  }

  .general-info__title_type_mobile {
    display: block;
  }

  .general-info__text {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .general-info__text {
    line-height: 1.75;
  }
}

@media (max-width: 740px) {
  .general-info {
    padding-right: 24px;
  }

  .general-info__container {
    gap: 26px;
  }

  .general-info__content {
    gap: 26px;
  }

  .general-info__img-box {
    height: 148px;
  }

  .general-info__title {
    font-size: 40px;
    line-height: 1.15;
    letter-spacing: -0.8px;
    padding-left: 24px;
  }

  .general-info__text {
    line-height: 1.62;
  }
}

@media (max-width: 600px) {
  .general-info__content {
    flex-direction: column;
  }

  .general-info__text {
    padding-left: 24px;
  }
}
