.heading {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 50px;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 40px;
  scroll-margin-top: 56px;
}

.heading__title-block {
  display: grid;
  grid-template-columns: minmax(172px, 220px) 1fr;
  align-items: center;
  gap: 50px;
}

.heading__animation-box {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 600px;
  background-color: var(--anim-block-color);
  overflow: hidden;
}

.heading__anim-circle {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: var(--elements-color);
  position: absolute;
  bottom: 0;
  z-index: 0;
  opacity: 0;
  will-change: transform;
}

.heading__anim-box-icon {
  width: 118px;
  height: 78px;
  position: relative;
  z-index: 1;
}

.heading__anim-box-icon-fill {
  fill: var(--neutral-contrast-secondary);
}

.heading__text-box {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.heading__title {
  font-size: 84px;
  line-height: 1.05;
  letter-spacing: -1.68px;
  text-transform: uppercase;
}

.heading__text {
  color: var(--neutral-secondary);
  max-width: 420px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.44;
}

.heading__btn-block {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 52px;
  margin-bottom: 20px;
}

.heading__btn {
  width: fit-content;
  min-width: 192px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 96px;
  background: var(--elements-color);
  box-sizing: border-box;
  padding: 14px 24px;

  color: var(--neutral-contrast-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
}

.heading__anim-list {
  width: 100%;
  height: 144px;
  display: grid;
  grid-template-columns: repeat(10, var(--dot-size));
  grid-template-rows: repeat(4, var(--dot-size));
  justify-content: space-between;
  align-content: space-between;
}

.heading__anim-item {
  width: fit-content;
  height: fit-content;
  display: flex;
  will-change: opacity, transform;
}

@media screen and (max-width: 1100px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }

  .heading__title-block {
    gap: 40px;
  }

  .heading__text {
    max-width: 100%;
  }

  .heading__btn-block {
    max-width: 100%;
    flex-direction: row-reverse;
    align-items: center;
    gap: 76px;
    margin-bottom: 0;
  }

  .heading__anim-list {
    height: 62px;
    grid-template-rows: repeat(3, var(--dot-size));
  }
}

@media screen and (max-width: 740px) {
  .heading {
    gap: 32px;
    padding: 200px 0 26px;
  }

  .heading__title-block {
    grid-template-columns: 1fr;
  }

  .heading__animation-box {
    width: 146px;
    height: 260px;
    position: absolute;
    top: 0;
    right: 40px;
  }

  .heading__anim-box-icon {
    width: 78.11px;
    height: 50.7px;
  }

  .heading__text-box {
    gap: 20px;
  }

  .heading__title {
    font-size: 64px;
    line-height: 1.06;
    letter-spacing: -1.28px;
  }

  .heading__text {
    font-size: 16px;
    line-height: 1.5;
  }

  .heading__btn {
    width: 100%;
    height: 56px;
    border-radius: 64px;
  }

  .heading__anim-list {
    height: 102px;
    grid-template-rows: repeat(4, var(--dot-size));
    position: absolute;
    top: 79px;
    left: 0;
    box-sizing: border-box;
    padding-right: 208px;
  }
}

@media screen and (max-width: 500px) {
  .heading__anim-list {
    grid-template-columns: repeat(7, var(--dot-size));
  }
}
