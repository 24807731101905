.depths {
  width: 100vw;
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-top: 90px;
}

.depths__container {
  width: 100%;
  max-width: 1360px;
  display: grid;
  grid-template-columns: 1fr 80px;
  justify-content: end;
  box-sizing: border-box;
  padding-left: 40px;
}

.depths__content {
  width: 100%;
  max-width: 1240px;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 600px;
  box-sizing: border-box;
  border: 1px solid var(--depths-content-color-primary);
  z-index: 2;
}

.depths__img-box {
  width: 160px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.depths__img {
  height: 100%;
}

.depths__text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;
  padding-right: 12.5%;
}

.depths__title {
  color: var(--main-primary-active);
  font-size: 30px;
  font-weight: 600;
  line-height: 1.13;
}

.depths__text {
  color: var(--neutral-secondary);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.depths__circle {
  width: 200%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: var(--depths-circle-color-primary);
  z-index: 1;
}

.depths__circle-inner {
  width: 16.25%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: var(--depths-circle-color-secondary);
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1100px) {
  .depths {
    padding-top: 40px;
  }

  .depths__container {
    grid-template-columns: 1fr 46px;
  }

  .depths__img-box {
    width: 94px;
  }

  .depths__text-box {
    padding-right: 6.16%;
  }

  .depths__title {
    font-size: 22px;
    line-height: 1.18;
  }

  .depths__text {
    font-size: 16px;
    line-height: 1.25;
  }
}

@media screen and (max-width: 740px) {
  .depths__container {
    grid-template-columns: 1fr 50px;
    padding-left: 16px;
  }

  .depths__img-box {
    width: 72px;
  }

  .depths__text-box {
    gap: 6px;
    padding-right: 8.36%;
  }

  .depths__title {
    font-size: 16px;
    line-height: 1.25;
  }

  .depths__text {
    font-size: 12px;
    line-height: 1.33;
  }
}

@media screen and (max-width: 560px) {
  .depths__text-box {
    max-width: 204px;
    padding: 0 0 0 28px;
  }
}
