.main-reviews {
  width: 100%;
  min-height: 132px;
  display: grid;
  grid-template-columns: 52px 1fr 52px;
  gap: 48px;
  position: relative;
}

.main-reviews__title {
  max-width: 182px;
  color: var(--main-primary-active);
  text-align: center;
  font-size: 60px;
  line-height: 1.1;
  letter-spacing: -1.2px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--website-background);
  z-index: 2;
}

.main-reviews__swiper {
  width: 100%;
  position: relative;
  z-index: 1;
}

.main-reviews__nav-button {
  width: 52px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 6px;
  margin-top: 35px;
}

.main-reviews__button-prev {
  transform: scaleX(-1);
}

.main-reviews__nav-icon {
  width: 100%;
  height: 100%;
}

.main-reviews__nav-icon-fill {
  fill: var(--reviews-neutral-primary);
}

.main-reviews .swiper-pagination {
  display: none;
}

@media screen and (max-width: 1100px) {
  .main-reviews {
    min-height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .main-reviews__title {
    max-width: unset;
    position: static;
    transform: unset;
    background-color: transparent;
  }

  .main-reviews__swiper {
    box-sizing: border-box;
    padding-bottom: 28px;
  }

  .main-reviews__nav-button {
    display: none;
  }

  .main-reviews .swiper-pagination {
    display: block;
  }
}

@media screen and (max-width: 740px) {
  .main-reviews {
    width: calc(100% + 32px);
    gap: 26px;
    margin-left: -16px;
  }

  .main-reviews__title {
    font-size: 40px;
    line-height: 1.15;
    letter-spacing: -0.8px;
  }

  .main-reviews__swiper {
    padding: 0 24px 24px;
  }
}
