.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
