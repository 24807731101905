.custom-input {
  width: 100%;
  display: grid;
  gap: 4px;
  position: relative;
  transition: opacity 0.3s ease-in;
  align-items: center;
}

.custom-input__preloader {
  grid-template-columns: 1fr max-content;
}

.custom-input_disabled {
  opacity: 0.6;
}

.custom-input__label {
  transform-origin: top left;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 0;
  pointer-events: none;

  color: var(--neutral-active);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  opacity: 0.7;

  left: 0px;
  margin: 0;
}

.custom-input_type_research-id .custom-input__label {
  font-size: 14px;
}

.custom-input_focused .custom-input__label {
  transition: opacity 0.3s ease-in;
  opacity: 0;
  pointer-events: auto;
  user-select: none;
  /* background-color: var(--bg-color); */
}

.custom-input__input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none;
  font-style: normal;

  box-sizing: border-box;
  margin-top: unset;
  z-index: 0;
  overflow: auto;
  padding: 0;

  transition: all 0.2s linear;
  width: 100%;
  color: var(--neutral-active);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  caret-color: var(--neutral-active) !important;
}

.custom-input__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--website-background) inset !important;
  /* Forces the background to white */
  -webkit-text-fill-color: var(--neutral-active) !important;
  /* Ensures the text color remains black */
  border: none;
  /* Optional: Adjust the border color if needed */
}

.custom-input__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--website-background) inset !important;
  /* Ensures consistency on focus */
  -webkit-text-fill-color: var(--neutral-active) !important;
}

.custom-input__input {
  transition: background-color 0s ease-in-out 0s;
  /* Ensures background transitions smoothly */
}

.custom-input__input::placeholder {
  color: var(--neutral-active);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  opacity: 0.7;
}


.custom-input__input::placeholder {
  color: var(--neutral-active);
}

.custom-input__input::-webkit-scrollbar {
  width: 3px;
}

.custom-input__input::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
  border: none;
  outline: none;
  background-color: transparent;
}

.custom-input__input::-webkit-scrollbar-thumb {
  background-color: var(--secondary-contrast-color);
  outline: none;
  border-radius: 100px;
  overflow: visible;
}

.custom-input__input_hide-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px var(--main-element-secondary-bg); */
  border: none;
  outline: none;
  background-color: transparent !important;
}

.custom-input__input:focus {
  border-color: rgba(255, 255, 255, 0.59);
}

.custom-input_type_research-id .custom-input__input {
  font-size: 14px;
}

.custom-input__input_type_with-icon {
  padding: 0;
}

.custom-input__input_valid {
  border-color: #ffffff;
}

.custom-input__input_error {
  color: var(--semantic_minus-active);
  /* border-bottom-color: var(--text-pinky-color); */
}

.custom-input__input:focus {
  outline: transparent;
}

.custom-input__error {
  position: absolute;
  top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 143%;
  color: var(--semantic_minus-active);
}

.custom-input__hint {
  position: absolute;
  top: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 143%;
  color: var(--neutral-active);
  opacity: 0.4;
}

.custom-input__password-button {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 20px;
  padding: 0;
  z-index: 1;
}

.custom-input__icon {
  width: 16px;
  height: 16px;
}

.custom-input__icon-stroke {
  stroke: var(--semantic_plus-active);
}

.custom-input__icon-fill_error {
  fill: var(--semantic_minus-active);
}

/* 
@media (min-width: 1700px){
.custom-input__label {
  font-size: calc((1700px - 390px) / (1280 - 390) * (14 - 10) + 10px);
}

.custom-input_type_research-id .custom-input__label {
  font-size: calc((1700px - 390px) / (1280 - 390) * (14 - 10) + 10px);
}
.custom-input__input {
  font-size: calc((1700px - 390px) / (1280 - 390) * (14 - 10) + 10px);
}
.custom-input_type_research-id .custom-input__input {
  font-size: calc((1700px - 390px) / (1280 - 390) * (14 - 10) + 10px);
}

.custom-input__error {
  font-size: calc((1700px - 390px) / (1280 - 390) * (14 - 10) + 10px);
}
} */
@media (max-width: 550px) {


  .custom-input_focused .custom-input__label {
    transform: translate(0, -1px) scale(0.75);
  }
}


@media (max-width: 500px) {
  .custom-input__error {
    font-size: 10px;
    top: calc(100% + 6px);
  }
}