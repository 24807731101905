.main-ai {
  width: 100%;
  height: 100%;
  min-height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 26px;
  border: 1px solid var(--main-ai-border);
  background: var(--main-ai-background);
  box-sizing: border-box;
  padding: 60px 32px 40px;
}

.main-ai__content {
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  flex: auto;
}

@media screen and (max-width: 1100px) {
  .main-ai {
    min-height: 504px;
    padding: 40px 32px 30px;
  }
}

@media screen and (max-width: 740px) {
  .main-ai {
    min-height: 416px;
    padding: 16px;
  }
}
