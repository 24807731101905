.waitlist-popup {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
}

.waitlist-popup__heading {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.waitlist-popup__title {
    color: var(--neutral-active);
    font-family: "Oswald";
    font-size: 38px;
    font-style: normal;
    font-weight: 300;
    line-height: 40px;
}

.waitlist-popup__subtitle {
    color: var(--neutral-active);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.waitlist-popup__input {
    padding: 16px 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--neutral-active);
}

.waitlist-popup__submit {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 80px;
    background-color: var(--main-primary-active);
    box-sizing: border-box;
    padding: 16px 20px;
    transition: all 0.3s ease-in-out;
    color: var(--ai-form-color-secondary);
}

.waitlist-popup__submit_disabled {
    background: var(--neutral-secondary);
    pointer-events: none;
    user-select: none;
    opacity: 0.3;
}