.review-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.review-card__content-box {
  display: grid;
  grid-template-columns: 74px 1fr;
  align-items: center;
  gap: 16px;
}

.review-card__img {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  border-radius: 50%;
}

.review-card__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}

.review-card__name {
  color: var(--neutral-secondary);
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  padding-left: 90px;
}

@media screen and (max-width: 1100px) {
  .review-card__content-box {
    grid-template-columns: 62px 1fr;
  }

  .review-card__name {
    padding-left: 78px;
  }
}

@media screen and (max-width: 740px) {
  .review-card__content-box {
    grid-template-columns: 50px 1fr;
    gap: 12px;
  }

  .review-card__name {
    padding-left: 62px;
  }
}
