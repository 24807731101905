.benefits-list {
  width: 100%;
}

.benefits-list__container {
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  gap: 44px;
  box-sizing: border-box;
}

.benefits-list__tag {
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  background-color: var(--tag-color);
  padding: 8px 14px;
}

.benefits-list__tag-text {
  color: var(--tag-text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.14;
}

.benefits-list__list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minMax(325px, 1fr));
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 90px;
}

.benefits-list__item {
  will-change: opacity, transform;
}

@media screen and (max-width: 1100px) {
  .benefits-list__container {
    gap: 40px;
  }

  .benefits-list__list {
    grid-template-columns: repeat(3, fit-content(216px));
  }
}

@media screen and (max-width: 740px) {
  .benefits-list__container {
    gap: 24px;
    padding: 0 24px;
  }

  .benefits-list__tag-text {
    font-size: 12px;
    line-height: 1;
  }

  .benefits-list__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}