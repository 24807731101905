.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 120px 40px 0;
  overflow: hidden;
}

.main__content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
}

@media screen and (max-width: 1100px) {
  .main {
    padding: 100px 40px 0;
  }

  .main__content {
    gap: 40px;
  }
}

@media screen and (max-width: 740px) {
  .main {
    padding: 40px 16px 0;
  }

  .main__content {
    gap: 26px;
  }
}
