.chat-bubble-loader {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
}

.chat-bubble-loader__dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--neutral-secondary);
}
