.cards-swiper {
  width: 46.15%;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.cards-swiper__swiper {
  width: 100%;
}

.cards-swiper__slide {
  border-radius: 26px;
}

.cards-swiper__slide.swiper-slide:not(.swiper-slide-visible) .swiper-slide-shadow {
  background: var(--cards-shadow-color-secondary);
}

.cards-swiper__slide.swiper-slide-next:not(.swiper-slide-visible) .swiper-slide-shadow,
.cards-swiper__slide.swiper-slide-prev:not(.swiper-slide-visible) .swiper-slide-shadow {
  background: var(--cards-shadow-color-primary);
}

.cards-swiper__swiper .swiper-pagination-fraction {
  height: fit-content;
  color: var(--neutral-secondary);
  text-align: right;
  font-family: Oswald;
  font-size: 20px;
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 20px 20px 0;
}

.cards-swiper__dots {
  display: flex;
  gap: 6px;
  position: absolute;
  right: -90px;
}

.cards-swiper__dot {
  width: 8px;
  height: 8px;
  display: flex;
  will-change: opacity, transform;
}

.cards-swiper__dot-icon {
  width: 100%;
  height: 100%;
}

.cards-swiper__dot-icon-fill {
  fill: var(--neutral-active);
}

.cards-swiper__swiper-button {
  transition: all 0.3s cubic-bezier(0.22, 1, 0.36, 1);
}

.cards-swiper__swiper-button-prev {
  transform: rotate(180deg);
}

@media screen and (max-width: 1400px) {
  .cards-swiper__dots {
    display: none;
  }
}

@media screen and (max-width: 1180px) {
  .cards-swiper {
    width: 46.49%;
  }
}

@media screen and (max-width: 1100px) {
  .cards-swiper__slide {
    border-radius: 20px;
  }

  .cards-swiper__swiper .swiper-pagination-fraction {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.32px;
    padding: 16px 16px 0;
  }
}

@media screen and (max-width: 740px) {
  .cards-swiper {
    width: calc(100% + 24px * 2);
    padding: 0 24px;
    margin-left: -24px;
  }

  .cards-swiper__slide {
    border-radius: 16px;
  }
}