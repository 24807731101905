.dots-divider {
  width: 100%;
  box-sizing: border-box;
  padding: 60px 0 40px;
}

.dots-divider__line {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--dots-list-length), 4px);
  justify-content: space-between;
}

.dots-divider__dot {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background: var(--dots-divider-neutral-primary);
  will-change: opacity, transform;
}

@media screen and (max-width: 1100px) {
  .dots-divider {
    padding: 40px 0;
  }
}

@media screen and (max-width: 740px) {
  .dots-divider {
    padding: 30px 0;
  }
}
