.story-formats {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.story-formats__title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.story-formats__title {
  color: var(--main-primary-active);
  text-align: center;
  font-size: 60px;
  line-height: 1.1;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.story-formats__img-box {
  width: 220px;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  background-color: var(--story-formats-color-accent-primary);
  overflow: hidden;
}

.story-formats__ellipse {
  width: 100%;
  height: 141.81%;
  border-radius: 50%;
  background-color: var(--story-formats-color-accent-secondary);
  position: absolute;
  left: -42.27%;
}

.story-formats__img-icon {
  width: 32.73%;
  height: 76.36%;
  position: absolute;
  will-change: transform;
}

.story-formats__icon-big-el-fill {
  fill: var(--main-primary-active);
}

.story-formats__icon-small-el-fill {
  fill: var(--story-formats-color-accent-tertiary);
}

.story-formats__arrow-box {
  width: 13.18%;
  height: 10.45%;
  display: flex;
  position: absolute;
  will-change: opacity;
}

.story-formats__arrow {
  width: 100%;
  height: 100%;
}

.story-formats__arrow-fill {
  fill: var(--neutral-contrast-secondary);
}

.story-formats__cards-block {
  width: 100%;
  max-width: 780px;
  display: flex;
  gap: 60px;
  box-sizing: border-box;
}

.story-formats__cards-box {
  width: 46.15%;
}

@media screen and (max-width: 1400px) {
  .story-formats {
    padding-right: 40px;
  }
}

@media screen and (max-width: 1180px) {
  .story-formats {
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding-right: 4.88%;
  }

  .story-formats__title-block {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 58px;
  }

  .story-formats__title {
    text-align: left;
  }

  .story-formats__arrow-box {
    transform: rotate(90deg);
  }

  .story-formats__cards-block {
    max-width: 100%;
    gap: 7%;
  }

  .story-formats__cards-box {
    width: 46.49%;
  }
}

@media screen and (max-width: 740px) {
  .story-formats {
    gap: 26px;
    padding-right: 0;
  }

  .story-formats__title-block {
    width: fit-content;
    gap: 32px;
  }

  .story-formats__title {
    font-size: 40px;
    line-height: 1.15;
    letter-spacing: -0.8px;
  }

  .story-formats__img-box {
    width: 112px;
  }

  .story-formats__cards-block {
    flex-direction: column;
    gap: 18px;
    padding: 0 8px;
  }

  .story-formats__cards-box {
    width: 100%;
  }
}
