.benefit-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.benefit-card__title-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}

.benefit-card__title {
  color: var(--neutral-secondary);
  font-size: 40px;
  line-height: 1.15;
  letter-spacing: -0.8px;
  text-transform: uppercase;
}

.benefit-card__img {
  width: 46px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  object-fit: cover;
}

.benefit-card__text {
  color: var(--neutral-tertiary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}

@media screen and (max-width: 1180px) {
  .benefit-card__title-box {
    flex-direction: column-reverse;
    gap: 24px;
  }
}

@media screen and (max-width: 1100px) {
  .benefit-card {
    gap: 16px;
  }

  .benefit-card__title-box {
    gap: 16px;
  }

  .benefit-card__title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -0.6px;
  }
}

@media screen and (max-width: 740px) {
  .benefit-card {
    gap: 14px;
  }

  .benefit-card__title-box {
    flex-direction: row;
  }

  .benefit-card__title {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: -0.48px;
  }
}
