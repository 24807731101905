.formats-card {
  width: 100%;
  height: 100%;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  gap: 96px;
  border-radius: 26px;
  border: 1px solid var(--heading-color-accent-primary);
  background: var(--website-background);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.formats-card_type_stories {
  border-color: var(--story-formats-color-accent-primary);
  /* box-shadow: 6px 0px 0px 0px var(--story-formats-color-accent-primary); */
}

.formats-card__img-box {
  width: fit-content;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.formats-card__img {
  height: 100%;
  position: relative;
  z-index: 2;
}

.formats-card__icon-box {
  width: var(--icon-width);
  height: var(--icon-height);
  position: absolute;
  right: calc(var(--icon-width) * -1);
  z-index: 1;
  will-change: transform;
}

.formats-card__icon-box_type_stories {
  bottom: calc(var(--icon-height) * -1);
  right: unset;
}

.formats-card__icon {
  width: 100%;
  height: 100%;
}

.formats-card__icon-fill {
  fill: var(--icon-color);
}

.formats-card__number {
  color: var(--neutral-secondary);
  font-family: Oswald;
  font-size: 20px;
  font-weight: 200;
  line-height: 1.2;
  letter-spacing: -0.4px;
  text-transform: uppercase;
  position: absolute;
  top: 20px;
  right: 20px;
}

.formats-card__text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 0 30px 12px;
}

.formats-card__title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.14;
}

.formats-card__text {
  color: var(--neutral-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
}

@media screen and (max-width: 1100px) {
  .formats-card {
    min-height: 418px;
    gap: 60px;
    border-radius: 20px;
  }

  .formats-card__number {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.32px;
    top: 16px;
    right: 16px;
  }

  .formats-card__text-box {
    gap: 10px;
    padding: 0 20px 12px;
  }
}

@media screen and (max-width: 740px) {
  .formats-card {
    min-height: 400px;
    gap: 58px;
    border-radius: 16px;
  }

  .formats-card__text-box {
    gap: 8px;
    padding: 0 16px 12px;
  }

  .formats-card__title {
    font-size: 24px;
    line-height: 1.17;
  }
}
