.main-how-works {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--main-how-works-background);
  box-sizing: border-box;
  padding: 60px 40px;
  scroll-margin-top: 56px;
}

.main-how-works__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.main-how-works__img-box {
  width: 100%;
  min-width: 480px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 36px;
  position: relative;
}

.main-how-works__icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
}

.main-how-works__icon-bg {
  width: 100%;
  height: 100%;
  background: var(--general-info-color-primary);
  mix-blend-mode: color-dodge;
  position: absolute;
}

.main-how-works__benefits-box {
  width: 100%;
  display: flex;
}

@media screen and (max-width: 1100px) {
  .main-how-works {
    padding: 40px;
  }

  .main-how-works__container {
    gap: 40px;
  }

  .main-how-works__img-box {
    gap: 20px;
  }
}

@media screen and (max-width: 740px) {
  .main-how-works {
    padding: 26px 24px;
  }

  .main-how-works__container {
    gap: 30px;
  }

  .main-how-works__img-box {
    gap: 12px;
  }

  .main-how-works__dots-list {
    width: 222px;
    height: 102px;
    grid-template-columns: repeat(10, 6px);
  }

  .main-how-works__benefits-box {
    width: calc(100% + 20px);
  }
}

@media (max-width: 600px) {
  .main-how-works__img-box {
    min-width: unset;
  }
}
