.works-card {
  display: grid;
  grid-template-columns: 114px 1fr;
  align-items: center;
  border-radius: 600px;
  border: 1px solid var(--main-how-works-background);
  background-color: var(--how-it-work-card-bg);
  box-sizing: border-box;
  will-change: width;
  transition: border-color 0.3s ease-in-out;
}

.works-card_active {
  border: 1px solid var(--how-it-work-card-border);
}

.works-card__btn-box {
  width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  border-radius: 600px;
  overflow: hidden;
}

.works-card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: opacity;
}

.works-card__btn {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--btn-background-color);
  will-change: opacity;

  color: var(--neutral-contrast-secondary);
  text-align: center;
  font-family: Oswald;
  font-size: 30px;
  font-weight: 200;
  line-height: 1.13;
  letter-spacing: -0.6px;
  text-transform: uppercase;
}

.works-card__text-block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  will-change: opacity;
}

.works-card__text-box {
  width: 100%;
  min-width: calc(264px + 42px * 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  padding: 0 42px;
  position: absolute;
  top: 0;
  left: 0;
}

.works-card__card-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.1;
}

.works-card__card-text {
  max-width: 264px;
  color: var(--neutral-tertiary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
}

@media screen and (max-width: 1100px) {
  .works-card__text-box {
    min-width: calc(264px + 20px * 2);
    padding: 0 20px;
  }
}
