.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 120px 40px 40px;
}

.footer__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

.footer__icon-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__icon-box {
  width: 100%;
  height: 100%;
}

.footer__logo-icon {
  width: 100%;
  height: 100%;
}

.footer__logo-icon-background {
  fill: var(--logo-bg-color);
}

.footer__logo-icon-border {
  stroke: var(--logo-border-color);
}

.footer__logo-icon-fill {
  fill: var(--logo-fill-color);
}

.footer__links-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__links-list_type_wrap {
  flex-wrap: wrap;
}

.footer__btn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 60px;
  border: 1px solid var(--btn-border-color);
  background: var(--btn-bg-color);
  box-sizing: border-box;
  padding: 11px 24px;

  color: var(--btn-text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
  white-space: nowrap;
}

.footer__btn_inverted {
  border: 1px solid var(--footer-neutral-secondary);
  background: var(--footer-neutral-secondary);
  color: var(--neutral-contrast-secondary);
}

.footer__btn-icon {
  width: 12px;
  height: 18px;
}

.footer__btn-icon-fill {
  fill: var(--neutral-contrast-secondary);
}

.footer__social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--btn-border-color);
  box-sizing: border-box;
}

.footer__link-icon {
  width: 100%;
  height: 100%;
}

.footer__link-icon-background {
  fill: var(--btn-bg-color);
}

.footer__link-icon-fill {
  fill: var(--btn-text-color);
}

@media screen and (max-width: 1100px) {
  .footer {
    padding: 80px 40px 40px;
  }

  .footer__container {
    max-width: 418px;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px 12px;
  }

  .footer__links-list_type_wrap {
    width: 100%;
  }
}

@media screen and (max-width: 740px) {
  .footer {
    padding: 52px 40px 26px;
  }

  .footer__container {
    /* justify-content: center; */
    gap: 26px 12px;
  }
}
