.how-it-works {
  width: 100%;
  display: grid;
  grid-template-columns: 7fr 13fr;
  grid-template-rows: 1fr;
  align-items: center;
  box-sizing: border-box;
}

.how-it-works__title-box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.how-it-works__title {
  color: var(--neutral-secondary);
  font-size: 40px;
  line-height: 1.65;
  letter-spacing: -0.8px;
  text-transform: uppercase;
  white-space: nowrap;
}

.how-it-works__line {
  width: 100%;
  height: 1px;
  background-color: var(--line-divider-neutral-primary);
}

.how-it-works__content-box {
  width: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .how-it-works {
    grid-template-columns: 3fr 7fr;
  }
}

@media screen and (max-width: 1100px) {
  .how-it-works {
    display: flex;
    flex-direction: column;
  }

  .how-it-works__title-box {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .how-it-works__title {
    line-height: 1.1;
  }

  .how-it-works__line {
    width: 1px;
    height: 30px;
    margin-left: 57px;
  }
}

@media screen and (max-width: 740px) {
  .how-it-works__title-box {
    align-items: center;
  }

  .how-it-works__title {
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: -0.48px;
  }

  .how-it-works__line {
    height: 26px;
    margin-left: 0;
  }

  .how-it-works__content-box {
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid var(--how-it-work-card-border);
    background-color: var(--how-it-work-card-bg);
    box-sizing: border-box;
    padding: 16px 16px 24px;
    overflow: hidden;
  }

  .how-it-works__card {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    will-change: opacity, transform;
  }

  .how-it-works__card-img {
    width: 62px;
  }

  .how-it-works__text-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .how-it-works__card-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.12;
  }

  .how-it-works__card-text {
    color: var(--neutral-tertiary);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
  }
}
