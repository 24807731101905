.header {
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  background-color: var(--website-background);
  box-sizing: border-box;
  padding: 12px 40px 4px;
  margin-top: 28px;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

.header_hide {
  top: -56px;
}

.header_show {
  top: 0;
}

.header__container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__content-block {
  display: flex;
  align-items: center;
}

.header__icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__icon-box_type_burger {
  display: none;
}

.header__icon {
  width: 100%;
  height: 100%;
}

.header__logo-icon-background {
  fill: var(--logo-bg-color);
}

.header__logo-icon-border {
  stroke: var(--logo-border-color);
}

.header__logo-icon-fill {
  fill: var(--logo-fill-color);
}

.header__icon-background {
  fill: var(--btn-bg-color);
}

.header__icon-border {
  stroke: var(--btn-text-color);
}

.header__icon-fill {
  fill: var(--btn-text-color);
}

.header__nav-list {
  display: flex;
  align-items: center;
}

.header__nav-link_active {
  pointer-events: none;
  user-select: none;
}

.header__btn {
  width: fit-content;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  border: 1px solid var(--btn-border-color);
  background: var(--btn-bg-color);
  box-sizing: border-box;
  padding: 10px 23px;
  transition: all 0.3s ease-in-out;

  color: var(--btn-text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
}

.header__btn_inverted {
  background: var(--btn-text-color);
  color: var(--btn-bg-color);
}

.header__nav-link_active .header__btn {
  color: var(--active-link-text-color) !important;
  background: var(--active-link-bg-color) !important;
}

@media screen and (max-width: 1100px) {
  .header {
    padding: 12px 40px 4px;
    margin-top: 18px;
  }
}

@media screen and (max-width: 740px) {
  .header {
    padding: 12px 16px 4px;
    margin-top: 4px;
  }

  .header__icon-box_type_burger {
    display: flex;
  }

  .header__nav {
    display: none;
  }

  .header__btn_mobile-hidden {
    display: none;
  }
}
