.get-started {
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.get-started__block {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 600px;
  background: var(--get-started-background);
  box-sizing: border-box;
  padding: 0 56px;
}

.get-started__button {
  width: 100%;
  max-width: 260px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 96px;
  background: var(--get-started-btn-color);

  color: var(--neutral-contrast-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.14;
}

.get-started__text {
  color: var(--neutral-secondary);
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
}

@media screen and (max-width: 1100px) {
  .get-started__block {
    padding: 0 40px;
  }

  .get-started__button {
    max-width: 224px;
  }

  .get-started__text {
    font-size: 14px;
    line-height: 1.43;
  }
}

@media screen and (max-width: 740px) {
  .get-started {
    flex-direction: column;
    gap: 30px;
    border-radius: 16px;
    background: var(--get-started-background);
    padding: 26px;
  }

  .get-started__block {
    height: unset;
    border-radius: 0;
    background: unset;
    padding: 0;
  }

  .get-started__button {
    max-width: 100%;
    height: 56px;
  }

  .get-started__text {
    font-size: 16px;
    line-height: 1.5;
  }
}
