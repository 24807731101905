@import url(./assets/fonts/Inter/stylesheet.css);

html[data-theme="light"] {
  --website-background: #FFFFFF;
  --popup-background: rgba(29, 14, 54, 0.89);
  --popup-background-transparent: rgba(29, 14, 54, 0);

  --neutral-active: #1D0E36;
  --neutral-contrast-active: #FFFFFF;
  --neutral-secondary: #1D0E36;
  --neutral-contrast-secondary: #FFFFFF;
  --neutral-tertiary: #766F82;

  --main-primary-active: #FC4374;

  --mobile-menu-neutral-primary: #1D0E36;
  --mobile-menu-neutral-secondary: #766F82;

  --line-divider-neutral-primary: #CACAD8;
  --line-divider-neutral-secondary: #1D0E36;
  --dots-divider-neutral-primary: #1D0E36;

  --heading-neutral-primary: #1D0E36;
  --heading-color-accent-primary: #A8DEF5;

  --story-formats-color-accent-primary: #FAE1CD;
  --story-formats-color-accent-secondary: #FECDA6;
  --story-formats-color-accent-tertiary: #E23462;

  --cards-shadow-color-primary: #C3EDFF;
  --cards-shadow-color-secondary: #A8DEF5;
  --cards-icon-color-first: #77D6FF;
  --cards-icon-color-second: #FF648D;
  --cards-icon-color-third: #725DA0;

  --benefits-tag-color-primary: #DBF4FF;
  --benefits-tag-color-secondary: #EBDBFF;

  --depths-content-color-primary: #F59BB1;
  --depths-circle-color-primary: #FFD7E1;
  --depths-circle-color-secondary: #F97197;

  --main-how-works-background: #FAF6FF;

  --general-info-neutral-primary: #1D0E36;
  --general-info-color-primary: #7B4BA1;

  --how-it-works-color-primary: #C58EDF;
  --how-it-works-color-secondary: #9F73C1;
  --how-it-works-color-tertiary: #8C5FAF;
  --how-it-works-highlight-color-primary: #DCA3F6;
  --how-it-works-highlight-color-secondary: #B586DF;
  --how-it-works-highlight-color-tertiary: #9868BE;
  --how-it-work-card-bg: #FFFFFF;
  --how-it-work-card-border: #FFFFFF;

  --get-started-background: #FFE1FA;
  --get-started-btn-color: #1D0E36;

  --reviews-neutral-primary: #1D0E36;

  --footer-neutral-primary: #F3F5FA;
  --footer-neutral-secondary: #1D0E36;

  --ai-form-color-primary: #6A43AB;
  --ai-form-color-secondary: #FFFFFF;

  --main-ai-background: rgba(255, 255, 255, 0.30);
  --main-ai-input-background: #FBF9FE;
  --main-ai-border: #E6DBF3;
  --main-ai-logo-color: #1D0E36;
}

html[data-theme="dark"] {
  --website-background: #1D0E36;
  --popup-background: rgba(29, 14, 54, 0.89);
  --popup-background-transparent: rgba(29, 14, 54, 0);

  --neutral-active: #E5EDF4;
  --neutral-contrast-active: #1D0E36;
  --neutral-secondary: #C0D1E4;
  --neutral-contrast-secondary: #E5EDF4;
  --neutral-tertiary: #C0D1E4;

  --main-primary-active: #FC4374;

  --mobile-menu-neutral-primary: #2E1F47;
  --mobile-menu-neutral-secondary: #766F82;

  --line-divider-neutral-primary: #716782;
  --line-divider-neutral-secondary: #8879A1;
  --dots-divider-neutral-primary: #E6DCF7;

  --heading-neutral-primary: #43365A;
  --heading-color-accent-primary: #A8DEF5;

  --story-formats-color-accent-primary: #FAE1CD;
  --story-formats-color-accent-secondary: #FECDA6;
  --story-formats-color-accent-tertiary: #E23462;

  --cards-shadow-color-primary: #685B7E;
  --cards-shadow-color-secondary: #493A64;
  --cards-icon-color-first: #77D6FF;
  --cards-icon-color-second: #FF648D;
  --cards-icon-color-third: #725DA0;

  --benefits-tag-color-primary: #3F3253;
  --benefits-tag-color-secondary: #675488;

  --depths-content-color-primary: #F59BB1;
  --depths-circle-color-primary: #FFD7E1;
  --depths-circle-color-secondary: #F97197;

  --main-how-works-background: #2E1F47;

  --general-info-neutral-primary: #43365A;
  --general-info-color-primary: #7B4BA1;

  --how-it-works-color-primary: #C58EDF;
  --how-it-works-color-secondary: #9F73C1;
  --how-it-works-color-tertiary: #8C5FAF;
  --how-it-works-highlight-color-primary: #DCA3F6;
  --how-it-works-highlight-color-secondary: #B586DF;
  --how-it-works-highlight-color-tertiary: #9868BE;
  --how-it-work-card-bg: #2E1F47;
  --how-it-work-card-border: #716782;

  --get-started-background: #43365A;
  --get-started-btn-color: #8C5FAF;

  --reviews-neutral-primary: #FFE1FA;

  --footer-neutral-primary: #1D0E36;
  --footer-neutral-secondary: #43365A;

  --ai-form-color-primary: #6A43AB;
  --ai-form-color-secondary: #FFFFFF;

  --main-ai-background: #3E2E58;
  --main-ai-input-background: #3F2E58;
  --main-ai-border: #716782;
  --main-ai-logo-color: #FC4374;
}

html {
  background-color: var(--website-background);
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--website-background);
  transition: background-color 0.2s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote, span {
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  font-style: normal;
  white-space: pre-line;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Oswald;
  font-weight: 200;
}

span {
  color: inherit;
  white-space: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
}

a {
  font-style: normal;
  text-decoration: none;
  color: var(--neutral-active);
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  font-family: Inter, sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
  color: var(--neutral-active);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea, label {
  font-family: Inter, sans-serif;
  font-style: normal;
  color: var(--neutral-contrast-active);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
  font-family: Inter, sans-serif;
  font-style: normal;
  -webkit-box-shadow: 0 0 0 500px var(--website-background) inset !important;
  /* можно изменить на любой вариант цвета */
  -webkit-text-fill-color: var(--neutral-active) !important;
  background-color: var(--website-background) !important;
  background-clip: content-box !important;
  position: relative;
  z-index: 0;
}

[popover] {
  background-color: transparent;
  border: none;
  padding: 0;
}

[popover]::backdrop {
  background-color: var(--popup-background);
}
