.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--popup-background);
  visibility: hidden;
  opacity: 0;
  z-index: 10000;
  transition: visibility 0.6s ease-in-out, opacity 0.3s ease-in-out 0.3s;
}

.mobile-menu_open {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.6s ease-in-out, opacity 0.3s ease-in-out;
}

.mobile-menu__container {
  width: 100%;
  max-width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 0 30px 30px 0;
  background: var(--website-background);
  box-sizing: border-box;
  padding: 0 16px;
  transform: translateX(-125%);
  transition: transform 0.6s ease-in-out;
}

.mobile-menu__container_open {
  transform: translateX(0);
}

.mobile-menu__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  padding: 16px 0 30px;
}

.mobile-menu__heading {
  width: 100%;
  display: flex;
  align-items: center;
}

.mobile-menu__icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu__icon-box_type_theme {
  border-radius: 50%;
  border: 1px solid var(--neutral-active);
}

.mobile-menu__icon-box_type_close {
  margin-left: auto;
}

.mobile-menu__icon {
  width: 100%;
  height: 100%;
}

.mobile-menu__logo-icon-background {
  fill: var(--logo-bg-color);
}

.mobile-menu__logo-icon-border {
  stroke: var(--logo-border-color);
}

.mobile-menu__logo-icon-fill {
  fill: var(--logo-fill-color);
}

.mobile-menu__close-icon-background {
  fill: var(--mobile-menu-neutral-primary);
}

.mobile-menu__close-icon-fill {
  fill: var(--neutral-contrast-secondary);
}

.mobile-menu__theme-icon-fill {
  fill: var(--neutral-active);
}

.mobile-menu__nav {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  padding-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile-menu__nav::-webkit-scrollbar {
  display: none;
}

.mobile-menu__links-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  box-sizing: border-box;
  padding: 0 56px;
}

.mobile-menu__item {
  width: fit-content;
  will-change: opacity, transform;
}

.mobile-menu__nav-link {
  width: fit-content;
  display: flex;
  align-items: center;
  position: relative;
}

.mobile-menu__nav-link::before {
  content: '';
  width: 7px;
  height: 7px;
  background: var(--neutral-active);
  transform: rotate(45deg);
  position: absolute;
  left: -30px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.mobile-menu__nav-link_active::before {
  opacity: 1;
}

.mobile-menu__link {
  width: fit-content;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}

.mobile-menu__link_type_nav {
  font-weight: 700;
}

.mobile-menu__divider-wrapper {
  width: calc(100% - 6px);
}

.mobile-menu__social-list {
  display: flex;
  align-items: center;
}

.mobile-menu__social-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--social-link-border-color);
  box-sizing: border-box;
}

.mobile-menu__link-icon {
  width: 100%;
  height: 100%;
}

.mobile-menu__link-icon-background {
  fill: var(--footer-neutral-primary);
}

.mobile-menu__link-icon-fill {
  fill: var(--neutral-active);
}

.mobile-menu__copyright {
  color: var(--mobile-menu-neutral-secondary);
  font-size: 10px;
  font-weight: 300;
  line-height: 1.4;
}

.mobile-menu__dots-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 310px;
}

.mobile-menu__dots {
  width: 52px;
  height: 490px;
  display: grid;
  grid-template-columns: repeat(3, 6px);
  grid-template-rows: repeat(12, 6px);
  justify-content: space-between;
  align-content: space-between;
  margin-left: 14px;
}

.mobile-menu__dot {
  width: 6px;
  aspect-ratio: 1 / 1;
  background: var(--main-primary-active);
  border-radius: 50%;
}
