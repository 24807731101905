.ai-form {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.ai-form__container {
  width: 100%;
  max-width: 624px;
  flex: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.ai-form__heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 1px;
  row-gap: 6px;
  box-sizing: border-box;
}

.ai-form__logo-btn {
  width: 40px;
  height: 40px;
  display: flex;
}

.ai-form__logo {
  width: 40px;
  height: 40px;
}

.ai-form__logo-background {
  fill: var(--main-ai-logo-color);
}

.ai-form__logo-border {
  stroke: var(--main-ai-logo-color);
}

.ai-form__logo-fill {
  fill: var(--neutral-contrast-secondary);
}

.ai-form__data-item {
  height: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-radius: 60px;
  border: 1px solid var(--neutral-active);
  box-sizing: border-box;
  padding: 11px 24px;
  max-width: 100%;
  overflow: hidden;
}

.ai-form__data-value {
  color: var(--neutral-active);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ai-form__content {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ai-form__content-box {
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
}

.ai-form__title {
  color: var(--neutral-active);
  text-align: center;
  font-size: 60px;
  line-height: 1.07;
  letter-spacing: -1.2px;
  text-transform: uppercase;
}

.ai-form__title_size_medium {
  text-align: left;
  font-size: 30px;
  line-height: 1.13;
  letter-spacing: -0.6px;
}

.ai-form__title_size_small {
  text-align: left;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.4px;
}

.ai-form__title_width_restricted {
  max-width: 342px;
}

.ai-form__subtitle {
  color: var(--neutral-secondary);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  padding-top: 12px;
}

.ai-form__story-box {
  margin-top: 20px;
}

.ai-form__text {
  color: var(--neutral-secondary);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.71;
  padding-top: 8px;
}

.ai-form__loader {
  margin-top: 20px;
}

.ai-form__more-btn {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ai-form-color-secondary);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.29;
  white-space: nowrap;
  border-radius: 60px;
  background: var(--main-primary-active);
  box-sizing: border-box;
  padding: 11px 24px;
  margin-top: 40px;
}

.ai-form__form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 600px;
  border: 1px solid var(--line-divider-neutral-primary);
  box-sizing: border-box;
  padding: 4px 4px 4px 20px;
  transition: all 0.2s ease-in-out;
}

.ai-form__form_first-not-triggered{
  animation: pulse-animation 3.5s infinite;
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    border-color: var(--neutral-secondary);
  }
  50% {
    transform: scale(1.01);
    border-color: var(--main-primary-active); 
  }
  100% {
    transform: scale(1);
    border-color: var(--neutral-secondary);
  }
}

.ai-form__input {
  width: 100%;
  color: var(--neutral-secondary);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  caret-color: var(--neutral-secondary) !important;
  border: none !important;
}

.ai-form__input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--main-ai-input-background) inset !important;
  /* Forces the background to white */
  -webkit-text-fill-color: var(--neutral-secondary) !important;
  /* Ensures the text color remains black */
  border: none !important;
  /* Optional: Adjust the border color if needed */
}

.ai-form__input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--main-ai-input-background) inset !important;
  /* Ensures consistency on focus */
  -webkit-text-fill-color: var(--neutral-secondary) !important;
  border: none !important;
}

.ai-form__input {
  transition: background-color 0s ease-in-out 0s;
  border: none !important;
  /* Ensures background transitions smoothly */
}

.ai-form__input::placeholder {
  color: var(--neutral-secondary);
  border: none !important;
  opacity: 0.7;
}

.ai-form__submit-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--main-primary-active);
 
  box-sizing: border-box;
  padding: 10px 12px;
  transition: all 0.3s ease-in-out;
}

.ai-form__submit-btn_disabled {
  background: var(--neutral-secondary);
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
}

.ai-form__submit-icon {
  width: 20px;
  height: 16px;
  transform: rotate(-90deg);
}

.ai-form__submit-icon-fill {
  fill: var(--ai-form-color-secondary);
}

@media screen and (max-width: 1100px) {
  .ai-form__more-btn {
    margin-top: 24px;
  }
}

@media screen and (max-width: 740px) {
  .ai-form {
    gap: 16px;
  }

  .ai-form__container {
    gap: 16px;
  }

  .ai-form__title {
    font-size: 40px;
    line-height: 1.15;
    letter-spacing: -0.8px;
  }

  .ai-form__title_size_medium {
    font-size: 30px;
    line-height: 1.13;
    letter-spacing: -0.6px;
  }

  .ai-form__title_size_small {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: -0.4px;
  }

  .ai-form__title_width_restricted {
    max-width: 242px;
  }

  .ai-form__more-btn {
    margin-top: 16px;
  }
}
